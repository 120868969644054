import React, {useRef, useEffect, useCallback, useState} from 'react'
// import PropTypes from "prop-types";

const buildThresholdList = steps => {
  var thresholds = []
  for (var i = 1.0; i <= steps; i++) {
    var ratio = i / steps
    thresholds.push(ratio)
  }
  thresholds.push(0)
  return thresholds
}

const listenToIntersections = (element, callback, options) => {
  let io
  if (typeof io === 'undefined' && typeof window !== 'undefined' && window.IntersectionObserver) {
    io = new IntersectionObserver(callback, options)
  }
  io.observe(element)
  return io
}

function withIOWrapper (
  WrappedComponent
  // root,
  // rootMargin,
  // thresholdSteps,
  // callback
) {
  return function (props) {
    const ref = useRef()
    const io = useRef()
    const {id} = props

    const [intersectionRatio, setIntersectionRatio] = useState(undefined)

    const importIO = async () => {
      await import('intersection-observer').then(() => {
        console.log('IntersectionObserver polyfill injected.')
      })
    }

    useEffect(() => {
      // Default values
      let IOSupported = false

      // Intersection Observer polyfill
      if (typeof window !== 'undefined' && !window.IntersectionObserver) {
        importIO()
      }
      // Check if browser (now) supports IntersectionObserver
      if (typeof window !== 'undefined' && window.IntersectionObserver) {
        IOSupported = true
      }

      if (IOSupported) {
        startObserving()
      }

      return () => {
        io.current.disconnect()
      }
    }, [])

    const handleIntersection = useCallback(entries => {
      // console.log(entries[0].intersectionRatio);
      setIntersectionRatio(entries[0].intersectionRatio)
    }, [])

    const startObserving = useCallback(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: buildThresholdList(10)
      }
      io.current = listenToIntersections(ref.current, handleIntersection, options)
    }, [])

    // TODO: pass the isVisible and hasBeenVisible state value back into the children
    return (
      <div id={id} ref={ref}>
        <WrappedComponent {...props} intersectionRatio={intersectionRatio} />
      </div>
    )
  }
}
withIOWrapper.propTypes = {
  // : PropTypes.node.isRequired
}

withIOWrapper.defaultProps = {
  root: null,
  rootMargin: '0px',
  thresholdSteps: 5
}

export default withIOWrapper

// IntersectionObserverWrapper.propTypes = {
//   children: PropTypes.any.isRequired,
//   thresholdSteps: PropTypes.number.isRequired,
//   callback: PropTypes.func.isRequired,
//   root: PropTypes.any,
//   rootMargin: PropTypes.string,
//   id: PropTypes.string,
//   containerStyle: PropTypes.object
// };
