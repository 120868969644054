import React, { useState, useContext, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';

import WindowContext from '../../contexts/WindowContext';

import chevronIcon from '../../assets/icons/chevron-down.svg';

import style from './Gallery.module.css';

const Gallery = ({ images }) => {
	// https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?fontsize=14&module=/src/Example.tsx&file=/src/Example.tsx

	const [[page, direction], setPage] = useState([0, 0]);
	const windowDimensions = useContext(WindowContext);

	// We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
	// then wrap that within 0-2 to find our image ID in the array below. By passing an
	// absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
	// detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
	const imageIndex = wrap(0, images.length, page);

	const paginate = (newDirection) => {
		setPage([page + newDirection, newDirection]);
	};

	const variants = useMemo(() => {
		return {
			enter: (direction) => {
				return {
					x: direction > 0 ? windowDimensions.width : -windowDimensions.width,
					opacity: 0,
				};
			},
			center: {
				zIndex: 1,
				x: 0,
				opacity: 1,
			},
			exit: (direction) => {
				return {
					zIndex: 0,
					x: direction < 0 ? windowDimensions.width : -windowDimensions.width,
					opacity: 0,
				};
			},
		};
	}, [windowDimensions]);

	return (
		<div className={style.container}>
			<AnimatePresence initial={false} custom={direction}>
				<motion.img
					key={page}
					src={images[imageIndex].image.asset.url}
					alt={images[imageIndex].image.caption}
					custom={direction}
					variants={variants}
					initial="enter"
					animate="center"
					exit="exit"
					transition={{
						x: { type: 'spring', stiffness: 300, damping: 200 },
						opacity: { duration: 0.3 },
					}}
					drag="x"
					dragConstraints={{ left: 0, right: 0 }}
					dragElastic={1}
					onDragEnd={(e, { offset, velocity }) => {
						const swipe = swipePower(offset.x, velocity.x);
						if (swipe < -swipeConfidenceThreshold) {
							paginate(1);
						} else if (swipe > swipeConfidenceThreshold) {
							paginate(-1);
						}
					}}
				/>
			</AnimatePresence>
			{/* <span>{images[imageIndex].image.caption}</span> */}
			<button onClick={() => paginate(1)}>
				<img src={chevronIcon} alt="Left" />
			</button>
			<button onClick={() => paginate(-1)}>
				<img src={chevronIcon} alt="Right" />
			</button>
		</div>
	);
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
	return Math.abs(offset) * velocity;
};

Gallery.propTypes = {};
Gallery.defaultProps = {};

export default Gallery;
