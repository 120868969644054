import React from 'react';

import TextField from '../ui/input/textField/TextField';
import TextArea from '../ui/input/textArea/TextArea';
import Button from '../ui/button/Button';
import Container from '../../hoc/container/Container';

import style from './ContactForm.module.css';

const ContactForm = (props) => {
	return (
		<Container containerClass={style.formContainer} large>
			<div className={style.form}>
				<div className={style.formSection}>
					<TextField name="name" label="Your Name" value={props.name} onChange={props.fieldChanged} />
					<TextField name="email" label="Your Email" value={props.email} onChange={props.fieldChanged} />
					<TextField name="phone" label="Your Telephone" value={props.phone} onChange={props.fieldChanged} />
				</div>
				<div className={style.formSection}>
					<TextArea name="message" label="Trip Info" value={props.message} onChange={props.fieldChanged} />
				</div>
			</div>
			<Button isOutlined={true} isTertiary={true} label="Send" isLoading={props.loading} onPress={props.sendMessage} />
			<p className={style.error}>{props.errorMessage}</p>
		</Container>
	);
};

export default ContactForm;
