import React from "react"
import style from "./ListItem.module.css"

const ListItem = props => {
  const size = props.text.length < 50 ? { width: "50%" } : { width: "100%" }

  return (
    <div className={style.container} style={size}>
      <p>{props.text}</p>
    </div>
  )
}

export default ListItem
