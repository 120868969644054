import React from 'react'
import PropTypes from 'prop-types'
// import Container from '../../hoc/container/Container';
import style from './InfoSection.module.css'
import withIOWrapper from '../../hoc/ioWrapper/IntersectionObserverWrapper'

const InfoSection = props => {
  const {b, heading, copy, intersectionRatio} = props
  const containerStyle = b
    ? [style.containerB, 'infosection'].join(' ')
    : [style.containerA, 'infosection'].join(' ')
  const headingStyle = b ? style.headingB : style.headingA
  const copyStyle = b ? style.copyB : style.copyA

  const imageStyle = b ? style.imgContainerB : style.imgContainerA

  return (
    <div className={style.outerContainer} style={{opacity: intersectionRatio || 0}}>
      <div className={containerStyle}>
        <div className={imageStyle}>
          <img className={style.image} src={props.image} alt={props.imageAlt} />
        </div>

        <div className={style.txtContainer}>
          <h3 className={headingStyle}>{heading}</h3>

          <div className={style.divider} style={{alignSelf: b ? 'flex-start' : 'flex-end'}} />

          <p className={copyStyle}>{copy}</p>
        </div>
      </div>
    </div>
  )
}

InfoSection.propTypes = {
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  b: PropTypes.bool.isRequired
}

InfoSection.defaultProps = {
  b: false
}

export default withIOWrapper(InfoSection)
