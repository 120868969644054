"TextArea.js";
import React from "react";
import style from "./TextArea.module.css";

const textArea = props => (
  <div className={style.container}>
    <textarea
      {...props}
      className={[style.textArea, props.classes].join(" ")}
    />
    <p className={props.value ? style.labelActive : style.label}>
      {props.label}
    </p>
  </div>
);

export default textArea;
