import React, { useRef, useCallback, useState, useEffect, useContext } from 'react';
import gsap from 'gsap';
import axios from 'axios';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { graphql } from 'gatsby';

// import SEO from '../components/seo'
import Container from '../hoc/container/Container';
import InfoSection from '../components/infoSection/InfoSection';
import ListItem from '../components/listItem/ListItem';
import Button from '../components/ui/button/Button';
import ContactForm from '../components/contactForm/ContactForm';

import { scrollToId } from '../functions/functions';
import backIcon from '../assets/icons/back.svg';
import WindowContext from '../contexts/WindowContext';
import style from './yacht.module.css';
import Gallery from '../components/gallery/Gallery';

gsap.registerPlugin(ScrollToPlugin);

export const query = graphql`
	query YachtTemplateQuery($id: String!) {
		yacht: sanityBoat(id: { eq: $id }) {
			id
			title
			subtitle
			slug {
				current
			}
			headerImage {
				caption
				asset {
					url
				}
			}
			locations {
				name
			}
			crew
			guests {
				sleeps
				cruises
			}
			price {
				currency
				daily
				overnight
				weekly
				purchase
			}
			length {
				f
				m
			}
			year {
				built
				refit
			}
			infoBlocks {
				copy
				heading
				image {
					asset {
						url
					}
				}
			}
			tenders
			amenities
			gallery {
				image {
					attribution
					caption
					asset {
						url
					}
				}
			}
		}
	}
`;

const YachtPage = (props) => {
	const { data, context } = props;
	// const [isScrolled, setIsScrolled] = useState(false)
	const mainTimeline = useRef(gsap.timeline());
	const isTransitioningHeader = useRef(false);
	const windowDimensions = useContext(WindowContext);
	// console.log('Yacht Page data:', {data}, {context})

	// Form State
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		phone: '',
		message: '',
	});
	const [formErrorMessage, setFormErrorMessage] = useState('');
	const [isSendingMessage, setIsSendingMessage] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = useCallback(() => {
		const scrollY = window.scrollY;
		if (scrollY < 0) {
			return;
		}
		if (isTransitioningHeader.current) return;
		if (scrollY > 80) {
			return transitionHeaderIn();
		} else if (scrollY < 80) {
			return transitionHeaderOut();
		}
	}, [isTransitioningHeader]);

	const transitionHeaderIn = useCallback(() => {
		isTransitioningHeader.current = true;
		mainTimeline.current.clear();
		mainTimeline.current
			.to('#yachtTitles', 0.5, { y: 0, scale: 1 })
			.to('#fixedHeaderBg', 0.5, { opacity: 1 }, 0)
			.to(
				'#enquireButton',
				0.3,
				{
					opacity: 1,
					x: 0,
				},
				0
			)
			.call(() => {
				isTransitioningHeader.current = false;
			})
			.to('#blurFilter', { duration: 1, attr: { stdDeviation: 8 } });
	}, []);

	const transitionHeaderOut = useCallback(() => {
		isTransitioningHeader.current = true;
		mainTimeline.current.clear();
		mainTimeline.current
			.to('#yachtTitles', { duration: 0.4, y: '20vh', scale: 2 }, 0)
			.to('#fixedHeaderBg', { duration: 0.4, opacity: 0 }, 0)
			.to('#enquireButton', {
				duration: 0.3,
				opacity: 0,
				x: 20,
			})
			.call(() => {
				isTransitioningHeader.current = false;
			})
			.to('#blurFilter', { duration: 1, attr: { stdDeviation: 0 } });
	}, []);

	const handleEnquireNow = useCallback(() => {
		scrollToId('contactForm');
	}, []);

	const formFieldChanged = useCallback((e) => {
		e.persist();
		setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	}, []);

	const sendMessage = async () => {
		if (!formData.name) {
			return setFormErrorMessage('Please enter your name.');
		} else if (!formData.email) {
			return setFormErrorMessage('Please enter an email.');
		} else if (!formData.phone) {
			return setFormErrorMessage('Please enter your telephone number.');
		} else if (!formData.message) {
			return setFormErrorMessage('Please enter a message.');
		}
		setFormErrorMessage('');
		setIsSendingMessage(true);

		await axios
			.post('https://superior-yachting.herokuapp.com/email', {
				name: formData.name,
				email: formData.email,
				phone: formData.phone,
				message: formData.message,
			})
			.then((response) => {
				// console.log(response);
				if (response.data === 'success') {
					console.log('Email sent successfully');
					setFormData({
						name: '',
						email: '',
						phone: '',
						message: '',
					});
					setIsSendingMessage(false);
				} else {
					setFormErrorMessage(
						'There was an error sending your message. Please try again or email us directly at info@superioryachting.com'
					);
					setIsSendingMessage(false);
				}
			})
			.catch((error) => {
				setFormErrorMessage(
					`There was an error sending your message. Please try again or email us directly at info@superioryachting.com. ${error}`
				);
				setIsSendingMessage(false);
			});
	};

	const yacht = data && data.yacht;
	// console.log("yacht", yacht);

	const returnFormattedPrice = (price) => {
		return new Intl.NumberFormat().format(price);
	};

	return (
		<>
			{/* <SEO title={project.title || 'Untitled'} /> */}
			<svg
				className={style.headerImage}
				viewBox={`0 0 ${windowDimensions.width} ${windowDimensions.height}`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<filter id="blurMe" y="-50%" height="200%" x="-50%" width="200%">
					<feGaussianBlur id="blurFilter" stdDeviation="0" />
				</filter>
				<image
					href={yacht.headerImage.asset.url}
					height={windowDimensions.height}
					width={windowDimensions.width}
					filter="url(#blurMe)"
					alt={yacht.headerImage.caption}
					preserveAspectRatio={windowDimensions.width > windowDimensions.height ? 'xMinYMid slice' : 'xMidYMax slice'}
					// Switch this based on the screen aspect ratio.
				/>
			</svg>

			{/* <img
        id='headerImage'
        className={style.mainImg}
        src={yacht.headerImage.asset.url}
        alt={yacht.headerImage.caption}
      /> */}
			<header className={style.fixedHeader} id="fixedHeader">
				<Container large containerClass={style.headerWrapper}>
					<div id="yachtTitles" className={style.yachtTitles}>
						<h2>{yacht.title}</h2>
						<h3>{yacht.subtitle}</h3>
					</div>
					<Button id="enquireButton" isOutlined isSecondary label="ENQUIRE" onPress={handleEnquireNow} />
				</Container>
				<div id="fixedHeaderBg" className={style.fixedHeaderBg} />
			</header>

			{/* CONTENT */}
			<div className={style.content}>
				{/* SPEC */}
				<Container large>
					<div className={style.specContainer}>
						<div className={style.specRow}>
							<h3>LOCATIONS</h3>
							<h3>
								{yacht.locations.map(
									(location, i) => location.name + (i === yacht.locations.length - 1 ? '' : ', ')
								)}
							</h3>
						</div>

						<div className={style.specRow}>
							<h3>GUESTS</h3>
							<h3>
								Sleeps {yacht.guests.sleeps} {yacht.guests.cruises ? `| Cruises ${yacht.guests.cruises}` : null}
							</h3>
						</div>

						<div className={style.specRow}>
							<h3>CREW</h3>
							<h3>{yacht.crew}</h3>
						</div>

						<div className={style.specRow}>
							<h3>LENGTH</h3>
							<h3>
								{yacht.length.m}M | {yacht.length.f}f
							</h3>
						</div>

						<div className={style.specRow}>
							<h3>PRICE</h3>
							<h3>
								{yacht.price.daily
									? `Daily: ${yacht.price.currency}${returnFormattedPrice(yacht.price.daily)} |`
									: null}
								{yacht.price.overnight
									? `Overnight: ${yacht.price.currency}${returnFormattedPrice(yacht.price.overnight)} |`
									: null}
								{yacht.price.weekly
									? `Weekly: ${yacht.price.currency}${returnFormattedPrice(yacht.price.weekly)}`
									: null}
								<br />
								<span className={style.priceSmallPrint}>+VAT +30% APA</span>
							</h3>
						</div>
					</div>
				</Container>

				{yacht.infoBlocks.map((block, index) => {
					return (
						<>
							<InfoSection
								key={index}
								b={index % 2 === 1}
								heading={block.heading}
								copy={block.copy}
								image={block.image.asset.url}
								imageAlt="TODO"
							/>
							<div style={{ height: 48, width: '100%' }} />
						</>
					);
				})}

				{/* GALLERY */}
				{yacht.gallery.length > 0 ? (
					<section style={{ marginBottom: '10vh' }}>
						<Gallery images={yacht.gallery} />)
					</section>
				) : null}

				{/* AMENITIES */}
				<Container large>
					<div className={style.amenitiesContainer}>
						<h3>AMENITIES </h3>
						<div className={style.divider} />
						<div className={style.flexWrapper}>
							{yacht.amenities
								? yacht.amenities.map((item) => {
										return <ListItem text={item} key={item} />;
								  })
								: null}
						</div>
					</div>
				</Container>

				<Container large>
					<div className={style.tendersContainer}>
						<h3>TENDERS AND TOYS</h3>
						<div className={style.divider} />
						<div className={style.flexWrapper}>
							{yacht.tenders
								? yacht.tenders.map((item) => {
										return <ListItem text={item} key={item} />;
								  })
								: null}
						</div>
					</div>
				</Container>

				<div id="contactForm">
					<h2 className={style.enquireHeading}>ENQUIRE ABOUT {yacht.title.toUpperCase()}</h2>
					<ContactForm
						{...formData}
						errorMessage={formErrorMessage}
						fieldChanged={formFieldChanged}
						sendMessage={sendMessage}
						loading={isSendingMessage}
					/>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '32px 0',
						backgroundColor: 'rgba(0,0,0,0.2)',
					}}
				>
					{/* TODO: Add this to the navigation bar */}
					{/* Create a seperate Nav */}
					<Button isContained label="Back to Yachts" onPress={() => window.history.back()} />
				</div>
			</div>
		</>
	);
};

export default YachtPage;

// TODO: Add a share button
